import { DialogProps } from "../../components/Dialog";

export const NETWORK_ERROR =
  "An potential network error occured. Please check your network status";
export const UNAUTHENTICATED_ERROR = "Invalid Authentication";
export const FORBIDDEN_ERROR =
  "Looks like you do not have permission to perform this action. If you think this is an error, contact your manager or an administrator for assistance.";
export const BAD_REQUEST_ERROR = "Bad Request - Invalid Arguments";
export const INTERNAL_SERVER_ERROR = "Internal Server Error";
export const NOT_FOUND_ERROR_CODE = "Not Found";
export const UNAUTHORIZED_ERROR_CODE = "unauthorized";
export const UNHANDLED_ERROR_CODE = "An unexpected error occured";

const errorDialog: DialogProps = {
  content: "",
  openDialog: false,
  title: "An Error Occured",
  accept: "OK",
};

export type ErrorMessage = {
  errorMessages?: string[] | string;
};

export const parseErrorMessage = (messages?: ErrorMessage) => {
  if (Array.isArray(messages?.errorMessages)) {
    return messages?.errorMessages.join("\n");
  }
  return messages?.errorMessages;
};

export const handleError = (
  status?: number,
  messages?: ErrorMessage,
): DialogProps => {
  if (!status) {
    return {
      ...errorDialog,
      content: NETWORK_ERROR,
      openDialog: true,
    };
  }

  const errorMessage = parseErrorMessage(messages);

  switch (status) {
    case 401:
      return {
        ...errorDialog,
        content: errorMessage || UNAUTHENTICATED_ERROR,
        openDialog: true,
      };
    case 403:
      return {
        ...errorDialog,
        content: errorMessage || FORBIDDEN_ERROR,
        openDialog: true,
      };
    case 400:
      return {
        ...errorDialog,
        content: errorMessage || BAD_REQUEST_ERROR,
        openDialog: true,
      };
    case 404:
      return {
        ...errorDialog,
        content: errorMessage || NOT_FOUND_ERROR_CODE,
        openDialog: true,
      };
    case 500:
      return {
        ...errorDialog,
        content: errorMessage || INTERNAL_SERVER_ERROR,
        openDialog: true,
      };
    case 503:
      return {
        ...errorDialog,
        content: errorMessage || UNHANDLED_ERROR_CODE,
        openDialog: true,
      };
    default:
      return {
        ...errorDialog,
        content: errorMessage || UNHANDLED_ERROR_CODE,
        openDialog: true,
      };
  }
};
